<template>
  <v-layout row justify-center>
    <v-dialog v-model="dialog" persistent max-width="730">
      <template></template>
      <v-card>
        <v-card-title class="headline">
          <h1>Escolha um ícone</h1>
          <a href="#"></a>
          <v-btn class="close-modal" @click="close">
            <i class="fal fa-times"></i>
          </v-btn>
        </v-card-title>
        <div class="pa-3">
          <v-flex class="display-flex grid-icon" md12>
            <i class="fas fa-user-shield" @click="setIcon" />
            <i class="fas fa-laugh-wink" @click="setIcon" />
            <i class="fas fa-money-bill-wave" @click="setIcon" />
            <i class="fas fa-hourglass-start" @click="setIcon" />
            <i class="fas fa-gem" @click="setIcon" />
            <i class="fas fa-heart" @click="setIcon" />
            <i class="fas fa-leaf-heart" @click="setIcon" />
            <i class="fas fa-home-heart" @click="setIcon" />
            <i class="fas fa-hands-heart" @click="setIcon" />
            <i class="fas fa-hand-holding-heart" @click="setIcon" />
            <i class="fas fa-briefcase-medical" @click="setIcon" />
            <i class="fas fa-globe-americas" @click="setIcon" />
            <!-- <i class="fas fa-file-certificate" @click="setIcon" /> -->
            <i class="fas fa-file-contract" @click="setIcon" />
            <!-- <i class="fas fa-t-shirt" @click="setIcon" /> -->
            <i class="fas fa-futbol" @click="setIcon" />
            <i class="fas fa-ticket" @click="setIcon" />
            <i class="fas fa-book" @click="setIcon" />
            <i class="fengi-icon-nrn-1 icon" @click="setIcon" />
            <i class="fengi-icon-nrn-2 icon" @click="setIcon" />
            <i class="fengi-icon-nrn-3 icon" @click="setIcon" />
            <i class="fengi-icon-nrn-4 icon" @click="setIcon" />
            <i class="fengi-icon-nrn-5 icon" @click="setIcon" />
            <i class="fengi-icon-nrn-50 icon" style="width: 80px" @click="setIcon" />
            <i class="fengi-icon-nrn-30 icon" style="width: 80px" @click="setIcon" />
            <i class="fengi-icon-nrn-20 icon" style="width: 80px" @click="setIcon" />
            <i class="fengi-icon-nrn-10 icon" style="width: 80px;font-size: 40px;top: 7px;position: relative;" @click="setIcon" />
            <i class="fengi-icon-nrn-guest icon" @click="setIcon" />
            <i class="fengi-icon-nrn-play icon" @click="setIcon" />
            <i class="fengi-icon-nrn-shirt icon" @click="setIcon" />
            <i class="fengi-icon-nrn-shirt9 icon" @click="setIcon" />
            <i class="fengi-icon-nrn-shop icon" @click="setIcon" />
            <i class="fengi-pix icon" @click="setIcon" />
            <i class="fengi-ticket-discount icon" @click="setIcon" />
            <i class="fengi-user-check icon" @click="setIcon" />
            <i class="fengi-magnet icon" @click="setIcon" />
            <i class="fengi-icon-nrn-ticket icon" @click="setIcon" />
          </v-flex>
        </div>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script type="plain/text">
export default {
  data: () => {
    return {
      dialog: false,
      icon: ""
    };
  },
  methods: {
    close() {
	  this.dialog = false;
      this.$emit("close", this.icon);
    },
    open() {
	  this.dialog = true;
	  this.icon = "";
    },
    setIcon(event) {
	  this.icon = event.target.className;
      this.close();
    }
  }
};
</script>