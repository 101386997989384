<template>
    <div class="section">
        <div class="head">
            <h4>Responsáveis pela assinatura dos recibos deste projeto</h4>
            <span class="red--text">Os recibos desse projeto requerem no mínimo {{signersLimit}} assinatura(s)</span>
        </div>
        <template>
            <v-layout row>
                <v-flex>
                    <div>
                        <v-list three-line>
                            <template v-for="(item, index) in items">
                                <v-divider inset :key="index"></v-divider>

                                <v-list-tile
                                        :key="item.title"
                                        avatar
                                        @click=""
                                >
                                    <v-list-tile-avatar>
                                        <img :src="avatar(item)" class="mt-3">
<!--                                        <v-icon class="grey lighten-1 white&#45;&#45;text mt-3 fa-2x">account_circle</v-icon>-->
                                    </v-list-tile-avatar>

                                    <v-list-tile-content>
                                        <v-list-tile-title v-html="item.name"></v-list-tile-title>
                                        <v-list-tile-sub-title v-html="ocup(item)"></v-list-tile-sub-title>
                                    </v-list-tile-content>

                                    <v-list-tile-action>
                                        <v-checkbox @click.passive="checkExistisPresidentIfNeeded(item)" v-model="item.currentUserRole.canSignReceipt" :readonly="disabledItemByLimitExceded(item)" ></v-checkbox>
                                    </v-list-tile-action>

                                </v-list-tile>
                            </template>
                        </v-list>
                    </div>
                </v-flex>
            </v-layout>
        </template>
    </div>
</template>

<script type="plain/text">
    import {IncentiveLaws} from "../../scripts/models/enums/incentiveLaws.enum";
    import ValidatableComponent from "../base/ValidatableComponent";
    import {UserOccupation} from "../../scripts/models/enums/userOccupation.enum";
    import CommonHelper from "../../scripts/helpers/common.helper";

    export default {
        extends: ValidatableComponent,
        name: "SignReceipt",
        data(){
            return {
                userOccupation: UserOccupation
            }
        },
        props: {
            items: Array,
            projectIncentiveLaw: Object
        },
        computed: {
            signersLimit(){
                return this.projectIncentiveLaw.id == IncentiveLaws.LeiFederalDoEsporte || this.projectIncentiveLaw.id == IncentiveLaws.LeiIncentivoCulturaRouanet ? 2 : 1;
            },
        },
        created() {
            this.validationMethod = this.validate;
            this.onValidationSuccessMethod = this.validationSuccess;
        },
        methods:{

            avatar(item){
                return "https://ui-avatars.com/api/?font-size=0.40&background=F1F2F5&color=80849A&size=70&name=" + item.name
            },
            ocup(item){
                if(this.items){
                    return item.currentUserRole.occupationDesc || UserOccupation.properties[item.currentUserRole.occupation].name
                }
            },
            validateSigners(){
                if(!this.items){
                    return false;
                }

                let cont = this.signersCount();

                if(cont == 0){
                    return false;
                }

                if(cont < this.signersLimit){
                    return false;
                }

                const president = this.hasPresident();
                if(this.signersLimit > 1 && !president){
                    return false;
                }

                return true;
            },
            validate() {
                return this.validateSigners();
            },
            validationSuccess(){
                return true;
            },
            hasPresident(){
                for (let i = 0; i < this.items.length; i++) {
                    let e = this.items[i];
                    if (e.currentUserRole.occupation === UserOccupation.President) {
                        return true;
                    }
                }
                return false;
            },
            disabledItemByLimitExceded(item){
                if(this.signersLimit > 1 && item.currentUserRole.occupation === UserOccupation.President){
                    item.currentUserRole.canSignReceipt = true;
                    return true;
                }
                if(this.signersCount() == this.signersLimit && !item.currentUserRole.canSignReceipt){
                    return true;
                }
                return false;
            },
            signersCount(){
                let len = 0;
                this.items.forEach(function(e){
                    if(e.currentUserRole.canSignReceipt){
                        len++;
                    }
                });
                return len;
            },
            checkExistisPresidentIfNeeded(item){
                if(item.currentUserRole.canSignReceipt && item.currentUserRole.occupation !== this.userOccupation.President && this.signersLimit > 1 && this.signersCount() == this.signersLimit){
                    if(!this.hasPresident()){
                        CommonHelper.swal("O presidente deve ser selecionado","","warning");
                    }
                }
            }
        },
    }
</script>