const UserOccupation = {
    President: 1,
    Representative: 2,
    RegularUser: 3,
    properties: {
        1: { name: "Presidente" },
        2: { name: "Representante" },
        3: { name: "Outros cargos" }
    }
};

const UserOccupationList = [
    { id: UserOccupation.President, name: "Presidente", active: true },
    { id: UserOccupation.Representative, name: "Representante", active: true },
    { id: UserOccupation.RegularUser, name: "Outros cargos", active: true }
]

export { UserOccupation, UserOccupationList };