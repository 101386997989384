import File from "@/scripts/models/file.model";
export default class Supporter {

    constructor() {
        this.Order = 0;
        this.id = 0;
        this.name = '';
        this.show = false;
        this.file = new File();
    }
}