import BaseService from "./base.service";

export default class CampaignAppreciationDonationProduct extends BaseService{
    constructor(){
        super('CampaignAppreciationDonationProduct');
    }
    listAllByCampaignId(campaignId){
        return this.getRequest('List', {campaignId})
    }
    listAllByCampaignAppreciationId(campaignAppreciationId){
        return this.getRequest('ListByCampaignAppreciationId', {campaignAppreciationId})
    }
    save(callback, campaignAppreciationDonationProducts){
        this.postRequest('Save', campaignAppreciationDonationProducts).then(callback)
    }
}