<template>
  <div>
    <v-layout column class="item b-none" style="border-bottom:none" v-for="(goal, index) in items" :key="index">
      <h4>{{ goal.name }}</h4>
      <v-layout align-center>
        <v-flex xs12>
          <h5>{{ goal.amount | FormatNumber}}</h5>
        </v-flex>
      </v-layout>
      <!-- Nao exibe o primeiro item que sempre será de beneficiarios diretos -->
      <div v-if="index != 0" class="tools">
        <button @click="edit(goal)">
          <i class="fal fa-pencil"></i>
        </button>
        <button @click="openConfirmModal(goal)">
          <i class="fal fa-trash"></i>
        </button>
      </div>
    </v-layout>
    <AucAlert
      ref="alertComponent"
      title="Você tem certeza que deseja excluir essa meta?"
      confirmButtonText="Sim"
      cancelButtonText="Não"
      @confirm="confirm"
    />
  </div>
</template>

<script type="plain/text">
import AucAlert from "@/components/AucAlert.vue";
import { constants } from "crypto";
export default {
  components: {
    AucAlert
  },
  props: ["items"],
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    openConfirmModal(item) {
      this.selectedGoal = item;
      this.$refs.alertComponent.open();
    },
    confirm() {
      this.$emit("delete", this.selectedGoal);
    }
  },
  data() {
    return {
      selectedGoal: Object
    };
  }
};
</script>
