
export default class CampaignAppreciationItem {

	constructor() {
		this.id = 0;
		this.campaignAppreciationId = 0;
		this.icon = 'fas fa-heart';
		this.description = '';
		this.highlight = true;
	}
}