import BaseService from "./base.service";

export default class CampaignAppreciationCampaignProduct extends BaseService{
    constructor(){
        super('CampaignAppreciationCampaignProduct');
    }
    listAllByCampaignId(campaignId){
        return this.getRequest('List', {campaignId})
    }
    getCampaignProductByCampaignAppreciationId(campaignAppreciationId){
        return this.getRequest('GetCampaignProductByCampaignAppreciationId', {campaignAppreciationId})
    }
    save(callback, campaignAppreciationCampaignProducts){
        this.postRequest('Save', campaignAppreciationCampaignProducts).then(callback)
    }
}